import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    localStorage.setItem("token", "123")
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <div>
            <h1>Home</h1>
            <a href="/vendor">Vendor</a>
          </div>
        </Route>
        <Route path="/abc">
          <h1>Abc</h1>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
